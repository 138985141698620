import React, { useEffect } from "react";
import Layout from "../components/Layout";
import { Typography } from "@material-ui/core";

function Thanks() {
  useEffect(() => {
    localStorage.removeItem("formation");
    localStorage.removeItem("formation_time");
  }, []);

  return (
    <Layout>
      <main className="error-page">
        <div className="error-container">
          <Typography align="center" component="h2" variant="h4">
            Nous avons bien reçu votre pré-inscription pour la formation{" "}
          </Typography>
          <Typography>
            Pour valider et finaliser votre inscription vous devez régler les
            frais d’inscription au niveau de nos locaux{" "}
          </Typography>

          <Typography>
            ou par virement bancaire et nous envoyer le document justifiant la
            transaction{" "}
          </Typography>
        </div>
      </main>
    </Layout>
  );
}

export default Thanks;
